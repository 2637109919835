<!--
 * @Author: zhouql
 * @Date: 2020-07-01 16:32:24
 * @LastEditTime: 2021-08-11 10:51:17
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\myFolder\syncRule\index.vue
-->
<template>
  <div class="sync-rule">
    <en-title-card name="同步规则" :needBack="needBack" @back="back">
      <div slot="right">
        <en-button icon="tianjia-anniutoubu" icon-color="fff" class="add-btn"  @click="addRuleSyncAlert">添加</en-button>
      </div>
    </en-title-card>
    <div class="main">
      <en-result type="NodataAdd" c v-if="!loading&&!tableData.length" :subTitle='subTitle'></en-result>
      <en-table :data="tableData" v-else :loading='loading' :height-config="{bottomHeight:0}">
        <en-table-column type="index" width="60"></en-table-column>
        <en-table-column
          title="对应分类"
          field="classifyName"
        >
        </en-table-column>
        <en-table-column
          title="业务模板"
          field="busMod"
        >
        </en-table-column>
        <en-table-column
          title="格式类型"
          field="filedType"
        >
        </en-table-column>
        <en-table-column
          title="触发设置"
          field="configType"
          width="565"
        >
        </en-table-column>
        <template #hoverRow="{row, rowIndex}">
          <en-expand-buttons
            :data="syncRulelisBtn"
            @button-click="handleButtonClick(row,rowIndex, arguments[1], arguments[0])"
          />
        </template>

      </en-table>
    </div>
    <addRuleSync ref="ruleSyncAlert"
       :sourceTableData='sourceTableData'
       :formData="formData"
       :is-edit="isEdit"
       :templateList='templateClass'
       :syncList='syncList'
       @success='fetchPage'
       ></addRuleSync>
  </div>
</template>

<script>
import { foldersService } from "@/api/folders";
import folderComm from "@/mixins/myFolder/folderComm.js";
import { getFileTypeList, getTriggerSetList } from "@/tools/folderVarCommon.js";
import addRuleSync from "../alert/addRuleSync.vue"; // 新增同步规则弹窗

// 数据范围弹窗
export default {
  name: "syncRule",
  components: { addRuleSync },
  mixins: [folderComm],

  data() {
    return {
      loading: false,
      tableData: [],
      sourceTableData: [],
      treeData: {}, // 树数据
      syncList: [],
      templateClass: [], // 业务建模分类
      needBack: true,
      subTitle: "<div style='font-size:14px;'>暂无数据，请先<span style='color:#3e90fe;'>添加</span>数据吧</div>",
      syncRulelisBtn: [
        {
          id: "edit",
          name: "编辑",
          icon: "bianji-liebiao",
          iconColor: "#26c393"
        },
        {
          id: "delete",
          name: "删除",
          icon: "shibai",
          iconColor: "#f76b6b"
        }
      ],

      id: "",
      visiable: "",
      formData: {},
      // 是否为编辑
      isEdit: false,
      fileTypeList: [],
      triggerSetList: []
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.loading = true;
      Promise.all([
        this.getTemplateClassify(), // 模板分类
        this.getSyncRule(), // 查询同步规则对应分类树
        this.querySyncRule(), // 查询同步规则列表
        this.selectCommonVar()
      ]).then(() => {
        this.selectClassName();
      });
    },
    fetchPage() {
      Promise.all([
        this.querySyncRule(), // 查询同步规则列表
        this.selectCommonVar()
      ]).then(() => {
        this.selectClassName();
      });
    },
    // 请求列表数据接口
    async querySyncRule() {
      try {
        this.loading = true;
        const res = await foldersService.getSyncList({});
        this.sourceTableData = Array.isArray(res) ? res : [];
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description: 返回
     * @param {type}
     * @return {type}
     */
    back() {
      this.$router.go(-1);
    },
    /**
     * @description: 查询数据对应中文
     * @param {type}
     * @return:
     */
    selectCommonVar() {
      this.fileTypeList = getFileTypeList(); // 获取文件数组
      this.triggerSetList = getTriggerSetList(); // 获取触发设置
    },
    /**
     * @description: 查询数据对应中文
     * @param {type}
     * @return:
     */
    selectClassName() {
      /**
       * @description: 查询分类ID名字
       * @param {arr,id}
       * @return:elData
       */
      function findID(arr, id) {
        let elData;
        arr.forEach((el) => {
          if (el.id === id) {
            elData = el;
          } else if (el.nodes && el.nodes.length > 0) {
            const nodeData = findID(el.nodes, id);
            if (nodeData) {
              elData = nodeData;
            }
          }
        });
        return elData;
      }
      /**
       * @description: 查询业务模板名字
       * @param {arr,id}
       * @return:busIdName
       */
      function findBusModName(arr, id) {
        let busIdName;
        arr.forEach((el) => {
          if (el.id === id) {
            busIdName = el.name;
          } else if (el.templateList && el.templateList.length > 0) {
            const nodeData = findBusModName(el.templateList, id);
            if (nodeData) {
              busIdName = nodeData;
            }
          }
        });
        return busIdName;
      }
      /**
       * @description: 获取单个模板ID
       * @param {arr,strid}
       * @return:
       */
      function getBusId(arr, strid) {
        let busName;// 单个模板名称
        const busNameArr = [];// 获取模板名称
        const idArr = strid.split(",");// 把字符串转换成数组
        idArr.forEach((item) => {
          busName = findBusModName(arr, item); // 调用匹配相应模板名称方法
          busNameArr.push({ busName }); // 存入数组
        });
        return busNameArr.map((item) => item.busName).join(",");// 返回转换好的相应名称字符串
      }
      /**
       * @description: 查询文件类型名字
       * @param {arr,id}
       * @return:fileIdName
       */
      function findFileName(arr, id) {
        let fileIdName;
        arr.forEach((el) => {
          if (el.value === id) {
            fileIdName = el.text;
          }
        });
        return fileIdName;
      }
      /**
       * @description: 获取单个文件ID
       * @param {arr,strId}
       * @return:
       */
      function getFileId(arr, strid) {
        let fileType;// 单个文件名称
        const fileNameArr = [];// 获取文件名称
        const fileIdArr = strid.split(",");// 把字符串转换成数组
        fileIdArr.forEach((item) => {
          fileType = findFileName(arr, item); // 调用匹配相应文件名称方法
          fileNameArr.push({ fileType }); // 存入数组
        });
        return fileNameArr.map((item) => item.fileType).join(",");// 返回转换好的相应名称字符串
      }
      /**
       * @description: 获取触发方式类型对应文本
       * @param {arr,strId}
       * @return:
       */
      function findTriggerType(arr, id) {
        let triggerText;
        arr.forEach((el) => {
          if (el.value === id) {
            triggerText = el.text;
          }
        });
        return triggerText;
      }
      const tableList = [];
      this.sourceTableData.forEach((v, ind) => {
        // 找到相应分类名称
        const elData = findID(this.syncList, v.classifyId);
        // 找到相应模板名称
        const busArrNameData = getBusId(this.templateClass, v.templateId);
        // 找相应文件类型
        const fileArrData = getFileId(this.fileTypeList, v.fileType);
        // 找到触发设置对应文本
        const triggerData = findTriggerType(this.triggerSetList, v.configType);

        console.log("拼接好的数据", elData, busArrNameData, fileArrData, triggerData);
        // 对应数据放进tableData渲染表格
        tableList.push({
          classifyName: elData.name, busMod: busArrNameData, filedType: fileArrData, configType: triggerData, synList: v.synList
        });
        if (ind === this.sourceTableData.length - 1) {
          this.loading = false;
        }
      });
      this.tableData = tableList;
    },
    /**
     * 表格按钮
     */
    handleButtonClick(row, index, btnData) {
      // 编辑按钮
      if (btnData.icon === "bianji-liebiao") {
        this.isEdit = true;
        this.formData = { ...this.sourceTableData[index] };
        this.$refs.ruleSyncAlert.visiable = true;
      }
      // 删除按钮
      if (btnData.icon === "shibai") {
        this.deleteRule(index);
      }
    },
    deleteRule(index) {
      this.$confirm("确认删除此条规则", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async() => {
        this.sourceTableData.splice(index, 1);
        await foldersService.setDataRange({ data: JSON.stringify(this.sourceTableData) });
        this.tableData.splice(index, 1);
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    },
    /**
     * @description: 新增同步规则弹窗
     * @param {type}
     * @return:
     */
    addRuleSyncAlert() {
      this.isEdit = false;
      this.formData = {};
      this.$refs.ruleSyncAlert.visiable = true;
    },
    /**
     * 获取数据范围弹窗
     */
    dataRangeAlert() {
      this.$refs.dataRangeAlert.$emit("dataRange");
    }
  }
};
</script>

<style lang="scss" scoped>
.sync-rule{
  .main{
    padding-top: 10px;
    height: 100%;
    background: #fff;
  }
  .en-result{
    margin-top: 18vh;
    /deep/.subtitle{
      width: auto;
    }
  }
}
</style>
