<!--
 * @Author: Zhouql
 * @Date: 2020-07-07 16:11:36
 * @LastEditTime: 2021-09-03 11:29:38
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\myFolder\alert\dataRange.vue
-->
<template>
    <en-dialog
    :visible.sync="innerVisible"
    title="数据范围"
    class="datarange-main"
    width="1100px"
    append-to-body
      >
    <div class="item-main">
      <en-dialog
          width="40%"
          title="指定基础数据"
          :visible.sync="innerDialogVisible"
          :modal-append-to-body='true'
          append-to-body
          >
        <en-transfer-data v-model="selectValue" ref="enTransferData"  class='basic-data'  v-if="innerDialogVisible" :config="transferSet"></en-transfer-data>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeInnerDialog">确 定</el-button>
      </div>
      </en-dialog>
      <en-button class='add-btns' icon="icontianjia-anniutoubu" icon-color="fff" @click="addAndItem">
        添加
      </en-button>
      <en-and-or
        class='datarange-or-and'
        v-for='(anditem,ind) in tableData'
        :key="ind"
        :length-of-and="tableData[ind].items.length"
        :index="ind"
        :line-height="43"
        :padding="17"
        @add='addRow'
        @deleteItem='deleteItem'
      >
        <en-and-or-column v-for="(columitem,_rowInd) in anditem.items" :key="_rowInd" :name='ind' :containerIndex='_rowInd'  @deleteRow="deleteRow">
          <en-select
            class='field-input field-fg'
            v-model="columitem.field"
            placeholder="请选择"
            :data="fieldList"
            value-key='id'
            data-mode='data'
            :props="fieldProps"
            filterable
            mode="tree"
            align="left"
            @change="(v)=>{onFieldChange(v,columitem)}"
          >

          </en-select>
          <el-input placeholder="请选择" class='field-input value-input-fg' size="small" readonly v-if="showPlaceholder(columitem.field)"></el-input>
          <el-input placeholder="请选择" class='field-input value-input-fg' size="small" readonly v-if="showPlaceholder(columitem.field)"></el-input>
          <en-select
             class='field-input symbo-fg'
            v-model="columitem.symbol"
            placeholder="请选择"
            v-if="columitem.symbolList"
            :data="columitem.symbolList"
            :props="symboProps"
            @change="(v)=>{onSymboChange(v,columitem)}"
          >
          </en-select>
          <en-select
              class='field-input type-fg'
              v-if="!columitem.hideType&&columitem.typeList"
              v-model="columitem.type"
              placeholder="请选择"
              value-key='field'
              data-mode='data'
              :data="columitem.typeList"
              :props="{value: 'field', label: 'name'}"
              @change="(v)=>{onTypeChange(v,columitem)}"
            >
          </en-select>
          <en-select
              class='field-input var-fg'
              v-if="!columitem.hideVariable&&columitem.vaList"
              v-model="columitem.variable"
              placeholder="请选择"
              value-key='id'
              data-mode='data'
              :data="columitem.vaList"
              :props="{ label: 'name',value: 'id',}"
              @change="(v)=>{onVarChange(v,columitem)}"
            >
          </en-select>
          <el-select
              class='field-input value-select-fg'
              size="small"
              v-if="!columitem.hideValue&&columitem.inputType==='select'"
              v-model="columitem.valueText"
              placeholder="请选择"
              readonly
              @focus="(v)=>{onSelectClick(v,columitem,_rowInd)}"
            >
          </el-select>
          <el-input
            v-model="columitem.value"
            class='field-input value-input-fg'
            :type="columitem.inputType"
            :maxlength="columitem.valueMaxLen"
            @change="(v)=>{onValueChange(v,columitem)}"
            v-if="!columitem.hideValue&&(columitem.inputType==='input'||columitem.inputType==='text')"
            size="small"  placeholder="请输入内容1"></el-input>

        </en-and-or-column>
      </en-and-or>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitDataRange"  size="small">确 定</el-button>
    </div>
    </en-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import { foldersService } from "@/api/folders";
import EnTransferData from "@/components/en-transfer-data";
import { totalReportService } from "@/api/index";

export default {
  name: "dataRange",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tempInfo: {
      type: Object,
      default() {
        return { };
      }
    }
  },
  components: {
    EnTransferData
  },
  data() {
    return {
      selectValue: [],
      curOperatRow: {},
      innerDialogVisible: false,
      transferSet: {
        isMulti: true, // 是否多选
        lazy: false, // 获取静态数据  请设置false
        choiceRule: true,
        selectMode: "tree",
        dependData: null
      },
      source: "", // 5（单选字段指定范围）
      variable1: [], // 组织机构全局变量
      variable2: [], // 人员全局变量
      variable7: [], // 时间全局变量
      setfilter: "", // ["000", "003", "004", "008"];  （流程状态）v4.2 业务建模功能定义中常规功能删除按钮使用限制只能选择“未提交”“已结束”“终止”“已提交”4个状态,可以传空
      tableData: [{
        items: [{
          variable: "", field: "", symbol: "", value: "", type: ""
        }]
      }],
      type: 3, // 1基础数据 2业务建模 3管理建模用
      fieldList: [],
      main: [], // 主表区
      opertorList: [
        {
          id: "0", value: "0", op: "", name: "", text: ""
        },
        {
          id: "1", value: "1", op: " == ", name: "等于", text: "等于"
        },
        {
          id: "2", value: "2", op: " != ", name: "不等于", text: "不等于"
        },
        {
          id: "3", value: "3", op: " in ", name: "包含", text: "包含"
        },
        {
          id: "4", value: "4", op: " nin", name: "不包含", text: "不包含"
        },
        {
          id: "5", value: "5", op: " >  ", name: "大于", text: "大于"
        },
        {
          id: "6", value: "6", op: " >= ", name: "大于或等于", text: "大于或等于"
        },
        {
          id: "7", value: "7", op: " <= ", name: "小于或等于", text: "小于或等于"
        },
        {
          id: "8", value: "8", op: " <  ", name: "小于", text: "小于"
        },
        {
          id: "9", value: "9", op: "", name: "不包含于", text: "不包含于"
        },
        {
          id: "10", value: "10", op: "", name: "包含于", text: "包含于"
        },
        {
          id: "11", value: "11", op: "", name: "属于", text: "属于"
        },
        {
          id: "12", value: "12", op: "", name: "不属于", text: "不属于"
        },
        {
          id: "13", value: "13", op: "", name: "不为空", text: "不为空"
        },
        {
          id: "14", value: "14", op: "", name: "为空", text: "为空"
        },
        {
          id: "15", value: "15", op: "", name: "时间段", text: "时间段"
        }
      ],
      columsTemplate: {
        field: {},
        symbol: "",
        variable: "",
        value: "",
        type: "",
        inputType: "select",
        hideValue: true,
        hideVariable: true,
        hideType: true,
        hideSymbol: true
      },
      fieldProps: {
        label: "name",
        children: "nodes",
        disabled: (data) => data.disabled
      },
      symboProps: {
        label: "name",
        value: "value"
      },
      value: "",
      options: []
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },

    innerVisible: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      }
    }
  },
  watch: {
    innerVisible: {
      handler(newVal) {
        newVal ? this.getTemplateFieldList() : undefined;
      },
      immediate: true
    }
  },

  methods: {
    showPlaceholder(columitem) {
      return !columitem.field || JSON.stringify(columitem.field) === "{}";
    },
    // 处理字段回显
    dealReviewField(fd) {
      this.getFieldAtd(fd);
    },
    numtoString(num) {
      if (Object.prototype.toString.call(num).slice(8, -1) === "Number") {
        return String(num);
      }
      return num;
    },
    // 查询字段列表
    async getTemplateFieldList() {
      this.tableData = [];
      let res = await foldersService.mdQueryTemplateFieldList({ templateId: this.tempInfo.id, type: 4 });
      !Array.isArray(res) ? res = [] : "";
      this.main = {
        id: "mainTopArea",
        name: "主表区",
        hasChildren: "000",
        field: "",
        disabled: true,
        nodes: this.getTreeDataNodes(res)
      };
      this.fieldList = [this.main];
      if (this.tempInfo.dataFiltrationStr.length) {
        this.tableData = this.tempInfo.dataFiltrationStr.map((item) => {
          const resItems = { items: [] };
          resItems.items = item.items.map((el) => {
            const atd = this.getFieldAtd(el.field);
            const tp = this.numtoString(atd.fieldType);
            const vType = this.numtoString(el.valueType);
            el = this.jianrongData(el);
            const res = {
              field: atd,
              hideSymbol: false,
              hideType: true,
              hideValue: true,
              hideVariable: true
            };
            this.onFieldChange(atd, res);
            res.symbol = `${el.perator}`;
            this.onSymboChange(res.symbol, res);
            if (tp === "1" || tp === "2") {
              res.value = el.value;
              res.inputType = "text";
            } else if (tp === "3" || tp === "4") {
              if (vType === "0") {
                res.type = { field: el.value };
                res.hideType = false;
              } else if (vType === "1") {
                res.type = { field: vType };
                res.hideValue = true;
                res.hideType = false;
              } else {
                res.type = { field: vType };
                res.value = el.value;
                res.inputType = "text";
                res.hideType = false;
              }
            } else if (tp === "5" || tp === "15" || tp === "33") {
              if ((el.areaObjType === "001" || el.areaObjType === "002") && tp !== "33") { // 人员 机构
                if (vType === "3") { // 变量
                  res.variable = { id: el.value };
                  res.hideVariable = false;
                } else if (vType === "30") { // 指定
                  res.variable = { id: "0" };
                  res.hideVariable = false;
                  res.value = this.dealResveValue(el.value, el.valueText, atd);
                  res.valueText = el.valueText;
                  res.inputType = "select";
                  res.hideValue = false;
                }
              } else {
                res.value = this.dealResveValue(el.value, el.valueText, atd);
                res.valueText = el.valueText;
                res.inputType = "select";
                res.hideValue = false;
              }
            } else if (tp === "7") {
              if (vType === "3") {
                // 暂不处理时间区间
              } else if (vType === "40") { // 时间点+指定时间
                res.type = { field: "4" };
                res.variable = { id: "0" };
                res.value = el.value;
                res.inputType = "text";
                res.hideValue = false;
                res.hideType = false;
                res.hideVariable = false;
              } else if (vType === "4") {
                res.type = { field: vType };
                res.hideType = false;
                res.variable = { id: el.value };
                res.hideVariable = false;
              } else {
                res.type = { field: el.value };
                res.hideType = false;
              }
            }
            this.$set(res, "variable", res.variable);
            return res;
          });
          return resItems;
        });
      }
    },
    // 数据兼容 处理 （主要是单选和时间字段）v4.3
    jianrongData(atd) {
      if (atd.valueType === undefined || atd.valueType === "") {
        atd.valueType = atd.selectType;
      } else {
        return atd;
      }

      const c = cloneDeep(atd);
      if (atd.fieldType === "5") {
        if (atd.areaObjType === "001") {
          c.valueType = "3";
          // 11,12 => 14,12
          c.value = c.value === "11" ? "14" : c.value;
        } else if (atd.areaObjType === "002") {
          c.valueType = "3";
          // 1,11,17,15,16 => 1,2,4,15,16
          switch (c.value) {
            case "11":
              c.value = "2";
              break;
            case "17":
              c.value = "4";
              break;
            default:
              break;
          }
        } else {
          c.valueType = "2";
        }
      } else if (atd.fieldType === "7") {
        if (atd.valueType === "1") { // 系统当前时间
          c.valueType = "4"; // 时间点
          c.value = "1";
        } else if (atd.valueType === "2") { // 指定时间
          c.valueType = "40"; // 时间点
        }
      }
      return c;
    },
    //
    getFieldAtd(fd) {
      let atd = {};
      this.fieldList.forEach((b) => {
        if (b.nodes && b.nodes.length) {
          atd = b.nodes.find((item) => item.field === fd);
        }
      });
      return atd;
    },
    // 保存数据范围
    submitDataRange() {
      const submitlist = [];
      for (let index = 0; index < this.tableData.length; index++) {
        const item = this.tableData[index];
        const { hasError, mapList } = this.dealData(item);
        if (hasError) {
          this.$message.error(hasError);
          return false;
        }
        submitlist.push(mapList);
      }
      console.log("submitlist", submitlist);
      this.$emit("setSuccess", submitlist.flat());
      this.innerVisible = false;
      return undefined;
    },
    // 处理值
    dealValue(colum) {
      let result = {}; let valueStr; let
        valueText;
      if (Array.isArray(colum.value)) {
        result = colum.value.map((v) => {
          if (colum.field.areaObjType && colum.field.areaObjType === "002") {
            return { id: v.type === "003" ? v.id : `dept-${v.id}`, name: v.name };
          }
          return { id: v.hasChildren === "000" ? `dept-${v.id}` : v.id, name: v.name };
        });
        valueStr = result.map((el) => el.id).join(",");
        valueText = result.map((el) => el.name).join(",");
      } else {
        valueStr = colum.value;
        valueText = colum.value;
      }
      return { valueStr, valueText };
    },
    // 处理回显值
    dealResveValue(valueStr, valueText, field) {
      let result = {};
      const valueList = valueStr.split(",");
      const valueTextList = valueText.split(",");
      if (valueList.length > 1) {
        result = valueList.map((v, index) => {
          if (field.areaObjType && field.areaObjType === "002") {
            return { type: !v.includes("dept") ? "003" : undefined, id: v.split("-").pop(), name: valueTextList[index] };
          }
          return { hasChildren: v.includes("dept") ? "000" : undefined, id: v.split("-").pop(), name: valueTextList[index] };
        });
      } else {
        result = [{ id: valueList[0].split("-").pop(), name: valueText }];
      }
      return result;
    },
    numberToStr(number) {
      const num = Number(number);
      isNaN(num);
    },
    // 处理数据
    dealData(item) {
      let mapList = [];
      let hasError = "";
      mapList = item.items.map((colum, ind) => {
        const oper = this.numtoString(colum.symbol);
        const ftp = this.numtoString(colum.field.fieldType);
        const res = {
          andOr: ind === item.items.length - 1 ? "2" : "1",
          field: colum.field.field,
          name: colum.field.name,
          fieldType: ftp,
          areaObjType: colum.field.areaObjType,
          areaObjTypeId: colum.field.areaObjTypeId,
          parentField: colum.field.parentField,
          parentFieldName: colum.field.parentFieldName,
          valueType: "0",
          perator: oper,
          value: "",
          valueText: ""
        };
        if (!ftp) {
          hasError = "存在未选择字段的行";
          return false;
        } if (!oper) {
          hasError = "存在未选择运算符号的行";
          return false;
        }
        // const columType = this.numtoString(colum.type);
        if (oper === "13" || oper === "14") { // 不为空 为空
          res.value = "";
          res.valueText = "";
        } else if (["1", "2"].includes(ftp)) {
          res.valueType = "2";
          res.value = colum.value;
        } else if (["3", "4"].includes(ftp)) {
          if (colum.type.field === "2") { // 固定值
            res.value = colum.value;
          } else { // 字段
            res.value = colum.type.field;
            res.valueType = "0";
          }
        } else if (["5", "15", "33"].includes(ftp)) {
          if ((colum.field.areaObjType === "001" || colum.field.areaObjType === "002") && ftp !== "33") {
            if (colum.variable.id === "0") {
              res.valueType = "30";
              res.value = this.dealValue(colum).valueStr;
              res.valueText = this.dealValue(colum).valueText;
            } else {
              res.valueType = "3";
              res.value = colum.variable.id || "";
              res.valueText = colum.variable.name || "";
            }
          } else {
            res.valueType = "2";
            res.value = this.dealValue(colum).valueStr;
            res.valueText = this.dealValue(colum).valueText;
          }
        } else if (ftp === "7") {
          if (colum.type.field === "3") { // 时间区间
          // 暂不处理
          } else if (colum.type.field === "4") { // 时间点
            if (colum.variable.id === "0") {
              res.value = this.dealValue(colum).valueStr;
              res.valueText = this.dealValue(colum).valueText;
              res.valueType = colum.type.field + colum.variable.id; // 时间点+指定时间  处理成 '40'
            } else {
              res.value = colum.variable.id || "";
              res.valueText = colum.variable.name || "";
            }
          } else { // 字段
            res.value = colum.type.field;
            res.valueText = colum.type.name || "";
            res.valueType = "0";
          }
        }
        if (!res.value && !["13", "14"].includes(oper)) {
          hasError = "存在未填写值的行";
          return false;
        }
        return res;
      });
      return { hasError, mapList };
    },
    closeInnerDialog() {
      this.curOperatRow.value = this.selectValue;
      this.curOperatRow.valueText = this.selectValue.map((item) => item.name).join(",");
      this.selectValue = [];
      this.innerDialogVisible = false;
    },
    getOpertor(sel) {
      const arr = [];
      sel.forEach((item) => {
        const d = cloneDeep(this.opertorList[item]);
        arr.push(d);
      });
      return arr;
    },
    // 获取变量
    async getVariable(areaObjType, fieldType) {
      let ty = "";
      let res = "";
      const ft = Number(fieldType);
      if (ft === 7) {
        ty = areaObjType;
      } else if (ft === 5 || ft === 15) {
        if (areaObjType === "001") {
          ty = 2;
        } if (areaObjType === "002") {
          ty = 1;
        }
      }
      res = await totalReportService.queryRbacGloableVariableList({ type: ty });
      const result = [];
      res.forEach((item) => {
        if (Number(item.enable) === 0 && ((this.userInfo.isSysManager === "001" && !item.isAdmin) || (this.userInfo.isSysManager === "000" && !item.isUser))) {
          result.push(item);
        }
      });
      return result || [];
    },
    // 根据字段类型设置符号
    setCellSymbol(field, row) {
      const ft = this.numtoString(field.fieldType);
      row.symbol = null;
      row.symbolList = [];
      if (["1", "2"].includes(ft)) {
        if (this.source === 5) {
          row.symbolList = this.getOpertor([1, 2, 13, 14]);
        } else {
          row.symbolList = this.getOpertor([1, 2, 3, 4, 13, 14]);
        }
      } else if (["3", "4", "7"].includes(ft)) {
        row.symbolList = this.getOpertor([1, 2, 5, 6, 7, 8, 13, 14]);
      } else {
        row.symbolList = this.getOpertor([9, 10, 13, 14]);
      }
    },
    // 根据字段类型设置变量 v4.3
    async setCellVariable(field, row) {
      const tp = this.numtoString(field.fieldType);
      const areaObjType = field.areaObjType;
      row.variable = "";
      let op = [];
      if ((tp === "5" || tp === "15") && areaObjType === "001") { // 组织机构
        op = await this.getVariable("001", tp);
        this.variable1 = op;
      } else if ((tp === "5" || tp === "15") && areaObjType === "002") { // 人员
        op = await this.getVariable("002", tp);
        this.variable2 = op;
      } else if (tp === "7") {
        op = await this.getVariable("4", "7");
        this.variable7 = op;
      }
      this.$set(row, "vaList", op);
    },
    // 根据字段类型 跟区域 设置选择类型
    setCellType(field, row) {
      const tp = this.numtoString(field.fieldType);
      const area = field.fieldArea;
      let op = [];
      row.hideType = false;
      row.type = null;
      if (tp === "1" || tp === "2" || tp === "5" || tp === "15" || tp === "33") {
        row.hideType = true;
      } else if (tp === "3") {
        op = [{ field: "2", name: "固定数值" }];
        if (area === 1) {
          this.fieldList[0].nodes.forEach((b) => {
            if (b.fieldType === "3" && b.field !== field.field && b.fieldArea === 1) {
              op.push(b);
            }
          });
        }
      } else if (tp === "4") {
        op = [{ field: "2", name: "固定金额" }];
        if (area === 1) {
          this.fieldList[0].nodes.forEach((b) => {
            if (b.fieldType === "4" && b.field !== field.field && b.fieldArea === 1) {
              op.push(b);
            }
          });
        }
      } else if (tp === "7") {
        // op = [{field:'1', name:"系统当前时间"}, {field: '2', name: '固定时间'}];
        op = [{ field: "4", name: "时间点" }];
        if (area === 1) {
          this.fieldList[0].nodes.forEach((b) => {
            if (b.fieldType === "7" && b.dateRange === 1 && b.field !== field.field && b.fieldArea === 1) {
              op.push(b);
            }
          });
        }
      }
      row.typeList = op;
    },
    // 设置值 操作
    setCellValue(field, row) {
      const tp = this.numtoString(field.fieldType);
      row.valueMaxLen = null;// 输入框最大输入长度
      this.$set(row, "value", null);
      row.inputType = "select";// 输入框类型
      row.hideSymbol = false;// 显示符号域
      row.hideVariable = true;// 隐藏变量选择域

      if (tp === "1" || tp === "2") {
        row.hideValue = false;
        row.inputType = "text";
      } else if (tp === "3" || tp === "4") {
        row.valueMaxLen = 10;
        row.inputType = "number";
      } else if (tp === "5" || tp === "15" || tp === "33") {
        if ((field.areaObjType === "001" || field.areaObjType === "002") && tp !== "33") {
          row.hideVariable = false;
          row.hideValue = true;
        }
        // 下拉选择
        row.inputType = "select";
        row.valueList = [];
        row.pa = {};
        row.pa = {
          ...cloneDeep(row.field),
          isMulti: true
        };
        if (this.setfilter) {
          row.pa.templateId = "";
          row.pa.filter = { billStatus: this.setfilter };
        } else if (row.field.field === "billStatus") {
          row.pa.templateId = "";
          row.pa.filter = { billStatus: ["000", "001", "002", "003", "004", "006", "008"] };
        } else if (row.field.field === "enable") {
          row.pa.templateId = "";
          row.pa.filter = { enable: ["000", "001"] };
        }
        this.transferSet.dependData = row.pa;
      } else if (tp === "7") {
        row.hideValue = true;
        // 时间选择
        row.inputType = "datePick";
      }
    },
    // 字段改变
    onFieldChange(field, row) {
      console.log("字段改变", field, row);
      this.setCellSymbol(field, row);
      this.setCellVariable(field, row);
      this.setCellType(field, row);
      this.setCellValue(field, row);
    },
    // 符号改变
    onSymboChange(item, row) {
      console.log("符号改变:", item);
      if (item === "13" || item === "14") { // 不为空 为空
        row.hideType = true;
        row.type = null;
        row.hideValue = true;
        row.value = null;
        row.hideVariable = true;
        row.variable = null;
      } else {
        if (!row.type || row.type === "0") {
          if (row.field.fieldType === "33") {
            row.hideValue = false;
          } else if (row.field.areaObjType === "001" || row.field.areaObjType === "002") {
            row.hideValue = row.variable !== "0";
            row.hideVariable = false;
          } else {
            row.hideValue = false;
          }
        } else if (row.type === "2") {
          row.hideValue = false;
        } else if (row.type === "4") {
          row.hideValue = row.variable === "0";
          row.hideVariable = false;
        }

        if (!["1", "2", "5", "15", "33"].includes(this.numtoString(row.field.fieldType))) {
          row.hideType = false;
        }
      }
    },
    // 字段类型改变
    onTypeChange(item, row) {
      row.hideValue = item.field !== "2";
      row.hideVariable = item.field !== "4";
    },
    // 变量改变
    onVarChange(item, row) {
      console.log("变量改变：", item);
      row.hideValue = item.id !== "0";
      // this.$nextTick(() => {
      //   row.variable = item;
      // });
    },
    // 点击值选择框
    onSelectClick(item, row) {
      this.curOperatRow = row;
      this.transferSet.dependData = row.pa;
      this.innerDialogVisible = true;
      this.$nextTick(() => {
        this.selectValue = row.value || [];
        this.$refs.enTransferData.$children[0].$children[0].rightPanel.list = this.selectValue;
      });
    },
    // 当值改变
    onValueChange(v, row) {
      console.log("当值改变", v);
      this.$set(row, "value", v);
    },
    getTreeDataNodes(list, pname) {
      const arr = [];
      const farr = [1, 2, 3, 4, 5, 6, 15, 33];

      list.forEach((item) => {
        if (farr.includes(Number(item.fieldType))) {
          item.id = item.field;
          item.hasChildren = "001";
          item.parentFieldName = pname || "";
          arr.push(item);
        } else {
          console.log(item);
        }
      });
      return arr;
    },
    addAndItem() {
      this.tableData.push({
        items: [{
          variable: "", field: "", symbol: "", value: "", type: ""
        }]
      });
    },
    /**
     * @description: 删除一个“且”条件
     * @param {*}
     * @return {*}
     */
    deleteItem(index) {
      this.tableData.splice(index, 1);
    },
    /**
     * @description: 添加行
     */
    addRow(index) {
      this.tableData[index].items.push(cloneDeep(this.columsTemplate));
    },
    /**
     * @description: 删除行
     * @param {index} 行索引
     */
    deleteRow(row) {
      this.tableData[row.name].items.splice(row.containerIndex, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.datarange-main{
  text-align: left;
  .field-input{
    width:190px;
    display:inline-block;
    margin-right:12px
  }
  .add-btns{
    margin-bottom: 20px;
  }
  .datarange-or-and{
    & /deep/ .en-icon{
      width:16px!important;
      height:16px!important;
    }
    & /deep/ .right{
      padding: 0!important;
    }
    & /deep/ .left .icon .hide{
      top: -13px!important;
    }
  }
  .item-main{
    height: 58vh;
    overflow: auto;
  }

}
.basic-data{
  height: 60vh;
}
</style>
