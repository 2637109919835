import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6508adc5&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=6508adc5&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6508adc5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev_en-user/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6508adc5')) {
      api.createRecord('6508adc5', component.options)
    } else {
      api.reload('6508adc5', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=6508adc5&scoped=true", function () {
      api.rerender('6508adc5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/myFolder/syncRule/index.vue"
export default component.exports