<!--
 * @Author: Zhouql
 * @Date: 2020-07-02 15:26:27
 * @LastEditTime: 2021-08-26 11:48:45
 * @LastEditors: wuqi
 * @Description: In User Settings Edit
 * @FilePath: \user\src\views\myFolder\alert\addRuleSync.vue
-->
<template>
  <el-dialog :visible.sync="visiable"
  :title="titleText" :close-on-click-modal='false' :close-on-press-escape='false' class="rules-main" width="700px" @close="visiable = false">
    <dataRange ref="dataRangeAlert" :visible.sync="dataRangeVisible" :key="curTempInfo.id" :tempInfo='curTempInfo' @setSuccess='dataRangeCb'></dataRange>
    <div class="main">
      <el-form ref="nForm" :model="syncForm" label-width="70px" label-position="left">
        <el-form-item label="对应分类" prop="classifyId">
          <en-select v-model="syncForm.classifyId" placeholder="请选择对应文档分类" mode="tree" :props="defaultProps" :data="syncList" check-mode="siblings" filterable> </en-select>
        </el-form-item>
        <el-form-item label="格式类型" prop="fileType">
            <en-select
              v-model="selectFileType"
              placeholder="请选择格式类型"
              :data="fileTypeList"
              :props="fileProps"
              align="left"
              multiple
              data-mode="data"
            >
            </en-select>
        </el-form-item>
        <el-form-item label="触发设置" prop="configType">
          <el-select v-model="syncForm.configType" placeholder="请选择">
            <el-option v-for="item in triggerSetList" :key="item.value" :label="item.text" :value="String(item.value)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务模板" prop="templateId">
          <en-select
            v-model="templateConfigList"
            :loading='loading'
            mode="tree"
            placeholder="请选择模板"
            :props="busdefaultProps"
            :data="templateList"
            filterable
            multiple
            data-mode="data"
            @change="templateConfigChange"
          >
          </en-select>
          <div class="bus-resultlis">
            <div class="result-item" v-for="(item,index) in syncForm.synList" :key="item.name">
              <el-input :value="item.name" class="bus-value" readonly="readonly"></el-input>
              <div class='result-item-btn'>
                <en-icon name="bianji-liebiao" size="16px" color="#26c393" class="edit" @click.native="dataRangeAlert(item)"></en-icon>
                <en-icon name="shanchu-liebiao" size="16px" color="#f76b6b" class="delete" @click.native="deleteRangeAlert(syncForm.synList,index)"></en-icon>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <en-button @click="submitForm()">
        确定
      </en-button>
    </div>
  </el-dialog>
</template>

<script>

import { cloneDeep } from "lodash";
import folderComm from "@/mixins/myFolder/folderComm.js"; // 公用方法
import { getFileTypeList, getTriggerSetList } from "@/tools/folderVarCommon.js";
import { foldersService } from "@/api/folders";
import dataRange from "./dataRange.vue";

export default {
  name: "addRuleSync",
  mixins: [folderComm],
  components: {
    dataRange
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {};
      }
    },
    templateList: {
      type: Array,
      default() {
        return [];
      }
    },
    sourceTableData: {
      type: Array,
      default() {
        return [];
      }
    },
    syncList: {
      type: Array,
      default() {
        return [];
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      visiable: false,
      curTempInfo: {}, // 选中的模板 传入datarange
      dataRangeVisible: false, // 显示数据范围弹框
      loading: false,
      selectFileType: [], // 格式类型
      templateConfigList: [],
      syncForm: {
        classifyId: "",
        configType: "",
        fileType: "",
        name: "",
        synList: [],
        templateId: ""
      },
      syncTreeData: {},
      // 分类props
      defaultProps: {
        children: "nodes",
        label: "name",
        value: "id"
      },
      // 文件类型props
      fileProps: {
        label: "text",
        value: "value"
      },
      // 模板 props
      busdefaultProps: {
        children: "templateList",
        label: "name",
        value: "id",
        disabled: (data) => (!data.classifyRefId && data.templateList.length) || Number(data.enableFlag) === 1
      },
      // 文件格式
      fileTypeList: [],
      // 触发设置列表
      triggerSetList: [],
      titleText: "新增同步规则"
    };
  },
  watch: {
    visiable(val) {
      if (val) {
        if (this.isEdit === true) {
          this.titleText = "编辑同步规则";
          console.log("编辑同步规则");
          this.templateConfigList = this.formData.templateId.split(",").map((value) => ({ id: value }));
          this.syncForm.classifyId = this.formData.classifyId;
          this.syncForm.fileType = this.formData.fileType;
          this.syncForm.templateId = this.formData.templateId;
          this.selectFileType = this.formData.fileType.split(",").map((value) => ({ value }));
          this.syncForm.configType = `${this.formData.configType}`;
          // 如果新增的时候没有设置数据范围，编辑修改的时候需要手动将模板回显
          this.templateConfigList = this.templateConfigList.map((item) => this.findSyncFormTemplate(this.templateList, item.id));
          this.templateConfigChange(this.templateConfigList);
        } else {
          this.syncForm = {};
          this.selectFileType = [];
          this.templateConfigList = [];
          this.titleText = "新增同步规则";
        }
        this.init();
      }
    }
    // visiable(val) {
    //   if (val) {

    //   }
    // }
  },
  mounted() {

  },
  methods: {
    init() {
      this.fileTypeList = getFileTypeList();
      this.triggerSetList = getTriggerSetList();
    },
    // 根据id递归找出对应模板的详细信息
    findSyncFormTemplate(list, id) {
      let res = "";
      let i = 0;
      while (!res && i <= list.length - 1) {
        if (list[i].id !== id && list[i].templateList) {
          res = this.findSyncFormTemplate(list[i].templateList, id);
        } else if (list[i].id === id) {
          res = list[i];
        }
        i++;
      }
      return res;
    },
    dataRangeCb(list) {
      this.syncForm.synList.forEach((synItem) => {
        synItem.templateId === this.curTempInfo.templateId ? synItem.dataFiltration = list : "";
      });
      console.log("this.syncForm.synList", this.syncForm.synList);
    },
    /**
     * 数据范围弹窗
     */
    dataRangeAlert(row) {
      // this.$emit("dataRange");
      this.curTempInfo = row;
      this.dataRangeVisible = true;
    },
    deleteRangeAlert(row, index) {
      row.splice(index, 1);
      this.templateConfigList.splice(index, 1);
    },
    templateConfigChange(list) {
      this.syncForm.synList = list.map((item) => {
        const res = {
          ...item,
          templateId: item.id

        };
        console.log(res, "res");
        let oldVal = {};
        if (this.formData.synList && this.formData.synList.length) {
          oldVal = this.formData.synList.find((el) => el.templateId === item.id) || {};
        }
        console.log(oldVal, "oldVal");
        res.dataFiltration = oldVal.dataFiltration || [];
        res.dataId = oldVal.dataId || "";
        res.dataFiltrationStr = oldVal.dataFiltrationStr || [];
        return res;
      });
    },
    /**
     * 保存
     */
    async submitForm() {
      this.visiable = false;
      let submitList = [];
      // console.log(this.syncForm);

      submitList = cloneDeep(this.sourceTableData);
      const addSync = cloneDeep(this.syncForm);
      addSync.synList = addSync.synList.map((item) => {
        const { dataFiltration, dataId, templateId } = { ...item };
        return { dataFiltration: dataFiltration || [], dataId: dataId || "", templateId: templateId || item.id };
      });
      addSync.fileType = this.selectFileType.map((s) => s.value).join(",");
      addSync.templateId = addSync.synList.map((s) => s.templateId).join(",");
      addSync.name = "";
      if (this.isEdit) {
        console.log(this.isEdit);
        const ind = submitList.findIndex((item) => item.id === this.formData.id);
        submitList.splice(ind, 1, addSync);
      } else {
        submitList.push(addSync);
      }
      await foldersService.setDataRange({ data: JSON.stringify(submitList) });
      this.$message.success(`${this.isEdit ? "修改" : "新增"}成功`);
      this.$emit("success");
    }
  }
};
</script>

<style lang="scss" scoped>
.rules-main {
  text-align: left;
  .el-form-item {
    margin-bottom: 10px;
    .en-select,
    .el-select {
      width: 100%;
    }
  }
  .bus-resultlis {
    margin-top: 10px;
    .result-item {
      position: relative;
      line-height: 32px;
      margin-bottom: 10px;
      .result-item-btn{
        display: flex;
        position: absolute;
        right: 10px;
        top: 0px;
        height: 100%;
        align-items: center;
      }
      .bus-value {
        margin-right: 10px;
      }
      .edit {
        margin-right: 10px;
      }
      .en-icon {
        visibility: hidden;
        cursor: pointer;
      }
      &:hover {
        /deep/ .el-input__inner {
          border-color: #e8ecf2;
          background-color: #f5f8fc;
        }
        .en-icon {
          visibility: visible;
        }
      }
      &:focus {
        /deep/ .el-input__inner {
          border-color: #e8ecf2;
          background-color: #f5f8fc;
        }
      }
    }
  }
}
</style>
